import * as React from 'react'
import Navbar from './navbar'
import Footer from './footer'
import * as style from './layout.module.css'

const Layout = ({ pageTitle, children }) => {
  return (
    <div className={style.container}>
      <title>{pageTitle}</title>
      <Navbar />
      <div className="site">
        <main className="site-content">
          <h1 className={style.spikedheadline}>{pageTitle}</h1>
          {children}
        </main>
      </div>
      <Footer />
    </div>
  )
}

export default Layout
