import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as style from './navbar.module.css'

const Navbar = () => {
  return (
    <header className={style.header}>
      <div>
        <div className={style.streamingNow}>STREAMING NOW:</div>
        <div className={style.navLogos}>
          <a
            href="https://tv.apple.com/us/movie/spiked/umc.cmc.2e60m59ug0t3aqpks67mjcvyo"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              alt="Watch Spiked on Apple TV"
              src="../images/logos/appletv.png"
            />
          </a>
          <a href="https://apple.co/3tBDZcZ" target="_blank" rel="noreferrer">
            <StaticImage
              alt="Watch Spiked on iTunes"
              src="../images/logos/itunes.png"
            />
          </a>
          <a
            href="https://www.amazon.com/Spiked-Aidan-Quinn/dp/B08Z25QWYW/ref=sr_1_1?dchild=1&keywords=spiked&qid=1616788264&s=instant-video&sr=1-1"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              alt="Watch Spiked on Amazon Prime"
              src="../images/logos/amazon.png"
            />
          </a>
          <a
            href="https://www.vudu.com/content/movies/details/Spiked/1653696"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              alt="Watch Spiked on Vudu"
              src="../images/logos/vudu.png"
            />
          </a>
          <a
            href="https://www.fandangonow.com/details/movie/spiked-2021/MMVB2F82BAAA21BBEC2E13E1ADB91FBAF413?ele=searchresult&elc=spiked&eli=0&eci=movies"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              alt="Watch Spiked on Fandango"
              src="../images/logos/fandango.png"
            />
          </a>
          <a
            href="https://www.youtube.com/watch?v=j2eYHOd1f5s"
            rel="noreferrer"
            target="_blank"
          >
            <StaticImage
              alt="Watch Spiked on YouTube"
              src="../images/logos/youtube.png"
            />
          </a>
          <a
            href="https://play.google.com/store/movies/details/Spiked?id=_KMzuvEvh0A.P&hl=en_US"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              alt="Watch Spiked on Google Play"
              src="../images/logos/googleplay.png"
            />
          </a>
          <a
            href="https://www.microsoft.com/en-us/p/spiked/8d6kgwxn7v9s?activetab=pivot%3aoverviewtab"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              alt="Watch Spiked on Microsoft"
              src="../images/logos/microsoft.png"
            />
          </a>
        </div>
      </div>
      <nav className={style.navLinks}>
        <Link to="/" className={style.navLink}>
          Trailer
        </Link>
        <Link to="/cast" className={style.navLink}>
          Cast
        </Link>

        <Link to="/gallery" className={style.navLink}>
          Gallery
        </Link>
        <Link to="/team" className={style.navLink}>
          Team
        </Link>
        <Link to="/news" className={style.navLink}>
          News
        </Link>
        <Link to="/contact" className={style.navLink}>
          Contact
        </Link>
      </nav>
    </header>
  )
}

export default Navbar
