import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import * as style from './footer.module.css'

const Footer = () => {
  return (
    <footer>
      <div className={style.footerLogos}>
        <a
          href="https://www.facebook.com/Spiked-Film-168415144935924"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            alt="Facebook Link for Spiked film"
            src="../images/logos/facebook.webp"
          />
        </a>
        <a
          href="https://www.instagram.com/spiked_film/"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            alt="Instagram Link for Spiked film"
            src="../images/logos/instagram.webp"
          />
        </a>
        <a
          href="https://www.imdb.com/title/tt9051162/"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            alt="IMDB Link for Spiked film"
            src="../images/logos/imdb.webp"
          />
        </a>
      </div>
    </footer>
  )
}

export default Footer
